import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import { useToasts } from 'react-toast-notifications'
import { deleteToCart, updateAllCartItems } from '@/redux/actions/cartAction'
import Icon from '@/components/icon/icon'
import ErrorItemsNode from '@/components/common/error-items-node'
import QtyDropdownInput from '@/components/cart/qty-dropdown-input'
import { formatPrice, addForwardSlash } from '@/lib/helpers'
import { addToWishlist } from '@/redux/actions/userAction'
import { updateCartModal, updateLoginModal } from '@/redux/actions/uiAction'
import { trans } from '@/lib/locale-helper'
import { getCartLocale, getCurrentRegion } from '@/lib/region-helper'
import { getLocalizedVariantPrices, getProductBySku } from '@/lib/product-helper'
import { removeFromCartGTM, addToWishlistGTM } from '@/lib/gtm'
import { getVariantMetafields } from '@/redux/actions/productAction'

const CartItem = ({ item, setLoading }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { isAuthenticated } = useSelector((state) => state.userReducer)
  const [price, setPrice] = useState(null)
  const [prodSlug, setProdSlug] = useState('')
  const [size, setSize] = useState('')

  const router = useRouter()
  const cartReducer = useSelector((state) => state.cartReducer)

  const getSize = () => {
    let itemSize = ''
    if (item.options && item.options.length) {
      itemSize = item.options.find((op) => op.name == 'Size').value
    }
    return itemSize
  }

  const getColor = () => {
    let itemColor = ''
    if (item.options && item.options.length) {
      itemColor = item.options.find((op) => op.name == 'Color').value
    }
    return itemColor
  }

  const deleteCartItem = async (item) => {
    setLoading(true)
    try {
      await dispatch(deleteToCart(item))

      //GTM
      const { product, variant } = item
      const removeFromCartDataObj = { ...product, ...variant }
      removeFromCartGTM(removeFromCartDataObj)
      //
    } catch (err) {
      // Handle Error Here
      console.error('deleteCartItem err', err)
      let em = []
      if (err.response && err.response.data) {
        em.push(err.response.data.message)
      } else {
        em.push(err.message)
      }
      addToast(<ErrorItemsNode errors={em} />, { appearance: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const handleClick = async (path) => {
    router.push(path)
  }

  const ProductImage = ({ item }) => {
    const { product } = item
    if (product && product.custom_url && product.custom_url.url && product.sku) {
      const productUrl = prodSlug
      return (
        <Link href={addForwardSlash(productUrl)}>
          <img className="w-full h-full cursor-pointer object-cover" src={item.image_url} />
        </Link>
      )
    } else {
      return <img className="w-full h-full object-cover" src={item.image_url} />
    }
  }

  const ProductName = ({ item }) => {
    const { product } = item
    if (product.custom_url && product.custom_url.url && product.sku) {
      const cUrl = prodSlug
      return (
        <Link className="cursor-pointer" href={addForwardSlash(`${cUrl}`)}>
          {item.name}
        </Link>
      )
    } else {
      return item.name
    }
  }

  const StockMsg = ({ item }) => {
    if (item.inventory_level < 0) {
      return (
        <div className="text-red-500 text-xs mt-1">
          {trans('currently-out-of-stock', 'Currently out of stock')}
        </div>
      )
    } else if ((item.inventory_level > 0) & (item.inventory_level < 6)) {
      return (
        <div className="text-red-500 text-xs mt-1">
          {item.inventory_level} {trans('remaining', 'Remaining')}
        </div>
      )
    }
    return <div className="mt-2"></div>
  }

  const addItem = async (item) => {
    setLoading(true)
    try {
      //GTM
      const { product } = item
      addToWishlistGTM(product)
      //

      let wishObj = {
        items: [
          {
            product_id: item.product_id,
            variant_id: item.variant_id,
          },
        ],
      }

      if (!isAuthenticated) {
        await dispatch(updateLoginModal(true))
        await dispatch(updateCartModal(false))
      } else {
        await dispatch(addToWishlist(wishObj))

        let allCartItems = cartReducer.all_cart_items
        let updatedAllCartItems = allCartItems.map((cartItem) => {
          if (item.id == cartItem.id) {
            return {
              ...item,
              added_to_wishlist: true,
            }
          }

          return cartItem
        })

        await dispatch(deleteToCart(item))

        await dispatch(updateAllCartItems(updatedAllCartItems,false))
      }
    } catch (err) {
      // Handle Error Here
      let em = []
      if (err.response && err.response.data) {
        em.push(err.response.data.message)
      } else {
        em.push(err.message)
      }
      addToast(<ErrorItemsNode errors={em} />, { appearance: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    let mounted = true
    const updateItemPrice = async () => {
      let cartLocale = getCartLocale(process.env.NEXT_PUBLIC_SITE_REGION)
      let productData = await getProductBySku(item?.product?.sku, false)
      // let addedVariant = productData.variants.find(variant => variant.id == item.variant.id)
      // let variantMetafields = addedVariant.metafields
      // if(!variantMetafields) {
      //   variantMetafields = await getVariantMetafields(item.product.id, item.variant.id)
      // }
      let variantMetafields = await getVariantMetafields(item.product.id, item.variant.id)

      let newPrice = getLocalizedVariantPrices(variantMetafields, cartLocale, {
        regularPrice: item.variant.price,
        salePrice: item.variant.sale_price,
      })

      if (item.list_price != newPrice.regularPrice) {
        newPrice = {
          ...newPrice,
          salePrice: Number(item.extended_list_price.toFixed(2)),
          calculatedPrice: Number(item.list_price.toFixed(2)),
        }
      }

      let discounts = item.discount_amount + item.coupon_amount

      if (discounts > 0) {
        let newSalePrice = item.extended_list_price - discounts

        newPrice = {
          ...newPrice,
          salePrice: Number(newSalePrice.toFixed(2)),
          calculatedPrice: Number(newSalePrice.toFixed(2)),
        }
      }

      if (mounted) {
        if(getCurrentRegion().domain != 'en-us') {
          let sizeLabel = variantMetafields.find(item => item.key.includes('_size'));
          if(sizeLabel) {
            sizeLabel = sizeLabel.value.replace(/[{"+}]/g, '')
            if(sizeLabel && sizeLabel != '') {
              setSize(sizeLabel)
            }
          }
        } else {
          let sizeLabel = getSize()
          setSize(sizeLabel)
        }
        setProdSlug(productData?.defaultSlug)
        setPrice(newPrice)
      }
    }

    updateItemPrice()

    return () => {
      mounted = false
    }
  }, [item])

  return (
    <>
      <div className="cart-item flex">
        <div className="cart-item__content flex w-full border-b border-gray-400">
          <div className="cart-item__image flex justify-center items-center flex-shrink-0">
            <ProductImage item={item} />
          </div>
          <div className="cart-item__details flex justify-between w-full">
            <div className="cart-item__details-left-col w-full">
              <div>
                <div className="cart-item__product-name w-full uppercase">
                  <ProductName item={item} />
                </div>
                <div className="cart-item__product-info text-gray-500">
                  {getColor()} <span className="px-1">&middot;</span> {size}
                </div>
                {(item && item.item_error) ||
                (item &&
                  item.product &&
                  item.product.custom_fields &&
                  item.product.custom_fields.find(
                    (op) => op.name.toLowerCase() === 'is_coming_soon' && op.value === 'true'
                  )) ||
                (item &&
                  item.product &&
                  item.product.custom_fields &&
                  item.product.custom_fields.find(
                    (op) => op.name.toLowerCase() === 'is_retired' && op.value === 'true'
                  )) ? (
                  <div className="flex justify-between items-end mb-2">
                    <div className="inline">
                      <div className="item_error">
                        <span>{trans('no-longer-available', 'No longer available.')} </span>
                        <span
                          className="text-decoration-underline"
                          onClick={() => deleteCartItem(item)}
                        >
                          {trans('remove-from-cart', 'Remove from cart.')}{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="cart-item__product-qty  flex justify-between flex-wrap items-center">
                  <div>
                    {!item.item_error &&
                    !(
                      item &&
                      item.product &&
                      item.product.custom_fields &&
                      item.product.custom_fields.find(
                        (op) => op.name.toLowerCase() === 'is_coming_soon' && op.value === 'true'
                      )
                    ) &&
                    !(
                      item.product.custom_fields &&
                      item.product.custom_fields.find(
                        (op) => op.name.toLowerCase() === 'is_retired' && op.value === 'true'
                      )
                    ) ? (
                      <div className="inline">
                        <div className="flex">
                          <QtyDropdownInput item={item} setParentLoader={setLoading} />
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <Icon
                          className={`fill-current text-white`}
                          viewBox={`0 0 20 19`}
                          size={19}
                          icon="heart-alt"
                        />
                        <button
                          type="button"
                          className="cart-item__wishlist-btn outline-none focus:outline-none pl-2"
                          onClick={() => addItem(item)}
                        >
                          {trans('move-to-wishlist', 'Move to Wish List')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <StockMsg item={item} />
                {!item.item_error &&
                  !(
                    item &&
                    item.product &&
                    item.product.custom_fields &&
                    item.product.custom_fields.find(
                      (op) => op.name.toLowerCase() === 'is_coming_soon' && op.value === 'true'
                    )
                  ) &&
                  !(
                    item.product.custom_fields &&
                    item.product.custom_fields.find(
                      (op) => op.name.toLowerCase() === 'is_retired' && op.value === 'true'
                    )
                  ) && (
                    <div className="flex items-center">
                      <Icon
                        className={`fill-current text-white`}
                        viewBox={`0 0 20 19`}
                        size={19}
                        icon="heart-alt"
                      />
                      <button
                        type="button"
                        className="cart-item__wishlist-btn  outline-none focus:outline-none pl-2 hover:underline"
                        onClick={() => addItem(item)}
                      >
                        {trans('move-to-wishlist', 'Move to Wish List')}
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="cart-item__details-right-col">
              <div>
                <Icon
                  className={`cursor-pointer text-gray-500 fill-current w-6 h-6 ml-auto`}
                  viewBox={`0 0 24 24`}
                  size={24}
                  icon="DeleteIcon"
                  onClick={() => deleteCartItem(item)}
                />
              </div>
              <div className="text-right">
                {price && (
                  <>
                    {price.salePrice &&
                    (price.salePrice || item.list_price != price.regularPrice) ? (
                      <div className="cart-item__price font-primary font-light inline-block">
                        <div className="line-through">
                          {cartReducer.currencySymbol}
                          {formatPrice(price.regularPrice * item.quantity)}
                        </div>
                        <div className="font-bold">
                          {cartReducer.currencySymbol}
                          {formatPrice(price.salePrice)}
                        </div>
                      </div>
                    ) : (
                      <div className="cart-item__price font-primary font-light">
                        {cartReducer.currencySymbol}
                        {formatPrice(item.extended_list_price)}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartItem
